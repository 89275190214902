import axios from "axios";
import https from 'https-browserify';

const httpsAgent = new https.Agent({ rejectUnauthorized: false });

const GuideApi = axios.create({
    baseURL: `${process.env.REACT_APP_GUIDE_API_URL}/tv`,
    timeout: 90000,
    httpsAgent
});

export default GuideApi;