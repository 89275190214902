import Loading from "../loading/loading.component";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { useRef, Fragment, useState, useEffect } from "react";
import { getChannelSchedule } from "../../utils/guide.utils";
import { Button } from "antd";

const Schedule = ({ channel, offset }) => {
    const toPrint = useRef();
    const [schedule, setSchedule] = useState([]);

    useEffect(() => {
        (async () => {
            let data = await getChannelSchedule(channel.channelid, offset);
            setSchedule(data);
        })();
    }, []);

    return (
        <Fragment>
            <ReactToPrint
                trigger={() => <Button type="primary" size="small">Print</Button>}
                content={() => toPrint.current}
                bodyClass="print"
            />
            <div
                style={{
                    maxWidth: "210mm",
                    display: "flex",
                    flexDirection: "column",
                    height: "calc(100vh - 100px)",
                    overflow: "auto",
                }}
            >
                {schedule.length ? (
                    <div ref={toPrint}>
                        <div
                            style={{
                                borderBottom: "1px solid lightgray",
                                padding: "10px",
                                fontWeight: "bold",
                                fontSize: "18px",
                                textAlign: "center",
                            }}
                        >
                            {channel.channelname} -{" "}
                            {moment
                                .unix(schedule[0]?.event[0]?.startTime)
                                .format("DD/MM/YYYY")}
                        </div>
                        {
                            schedule[0]?.event.map((p) => {
                                return (
                                    <div
                                        key={p.evtId}
                                        style={{
                                            borderBottom: "1px solid lightgray",
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "250px",
                                                borderRight: "1px solid lightgray",
                                            }}
                                        >
                                            <h4
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {p.name}
                                            </h4>
                                            <p
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {moment
                                                    .unix(p.startTime)
                                                    .format("HH:mm")}{" "}
                                                -{" "}
                                                {moment
                                                    .unix(p.startTime)
                                                    .add(p.duration, "second")
                                                    .format("HH:mm")}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                padding: "10px",
                                            }}
                                        >
                                            {p.description}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </Fragment>
    );
};

export default Schedule;
