import { useContext } from 'react';
import { GuideContext } from './contexts/guide.context';
import TabMenu from './components/tab-menu/tab-menu.component';
import Loading from './components/loading/loading.component';
import { Toaster } from 'react-hot-toast';

import './App.css';

function App() {

    const { status, channels } = useContext(GuideContext);

    return (
        <div className="App">
            {
                (!status.length && channels.length) ? <Loading /> : <TabMenu />
            }
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    );
}

export default App;
