import GuideApi from '../apis/guide.api';

export const getChannels = async () => {
    const {data} = await GuideApi.get('/channels');
    return data;
}

export const getChannelSchedule = async (channelId, offset) => {
    const {data} = await GuideApi.get(`/channels/${channelId}/schedule`, {
        params: {
            offset
        }
    });
    return data;
}