import { useContext, useRef, Fragment } from "react";
import { GuideContext } from "../../contexts/guide.context";
import { Tabs, Button, Empty } from "antd";
import { MinusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import Loading from "../loading/loading.component";

const CustomGuide = () => {
    const { setMultiChannel, multiChannel } = useContext(GuideContext);

    const onClearClick = () => {
        setMultiChannel([]);
        localStorage.setItem("customGuide", JSON.stringify([]));
    };

    return (
        <div
            style={{
                width: "100%",
                overflow: "hidden",
            }}
        >
            <Tabs
                defaultActiveKey="0"
                items={[
                    {
                        key: 0,
                        label: moment().format("DD/MM/YYYY"),
                        children: <GuideDay day={0} />,
                    },
                    {
                        key: 1,
                        label: moment().add(1, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={1} />,
                    },
                    {
                        key: 2,
                        label: moment().add(2, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={2} />,
                    },
                    {
                        key: 3,
                        label: moment().add(3, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={3} />,
                    },
                    {
                        key: 4,
                        label: moment().add(4, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={4} />,
                    },
                    {
                        key: 5,
                        label: moment().add(5, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={5} />,
                    },
                    {
                        key: 6,
                        label: moment().add(6, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={6} />,
                    },
                    {
                        key: 7,
                        label: moment().add(7, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={7} />,
                    },
                    {
                        key: 8,
                        label: moment().add(8, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={8} />,
                    },
                    {
                        key: 9,
                        label: moment().add(9, "days").format("DD/MM/YYYY"),
                        children: <GuideDay day={9} />,
                    },
                ]}
                style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                }}
                tabBarExtraContent={{
                    right: multiChannel.length ? (
                        <Button
                            type="primary"
                            icon={<MinusCircleOutlined />}
                            onClick={onClearClick}
                            size="small"
                            shape="round"
                            danger
                            style={{ marginRight: "20px" }}
                        >
                            Clear Guide
                        </Button>
                    ):undefined,
                }}
                type="card"
                size="small"
            />
        </div>
    );
};

const GuideDay = ({ day }) => {
    const { multiChannel, setMultiChannel, customLoading } = useContext(GuideContext);

    const toPrint = useRef();

    const getEventTimeWindow = () => {
        let allEvents = multiChannel
            .map((c) => {
                return c.schedule[day][0].event;
            })
            .flat()
            .sort((a, b) => {
                return a.startTime - b.startTime;
            })
            .map((e) => moment.unix(e.startTime).format("HH"));
        return {
            start: allEvents[0],
            end: allEvents[allEvents.length - 1],
            window: allEvents.filter((value, index, self) => {
                return self.indexOf(value) === index;
            }),
        };
    };

    const removeMultiChannel = (channelId) => {
        let updatedList = multiChannel.filter((c) => {
            return c.channelid !== channelId;
        });
        setMultiChannel(updatedList);

        localStorage.setItem(
            "customGuide",
            JSON.stringify(updatedList.map((c) => c.channelid))
        );
    };

    return customLoading ? (
        <Loading loadingText="Loading Custom Guide..." />
    ) : multiChannel.length ? (
        <Fragment>
            <div
                style={{
                    width: "100%",
                    height: "calc(100vh - 65px)",
                    overflow: "auto",
                }}
                ref={toPrint}
            >
                <div
                    style={{
                        display: "flex",
                        width: "fit-content",
                        gap: "10px",
                        borderBottom: "1px solid black",
                        fontWeight: "bold",
                        position: "sticky",
                        top: "0",
                        backgroundColor: "white",
                    }}
                >
                    <div
                        style={{
                            width: "150px",
                            marginRight: "10px",
                            borderRight: "1px solid black",
                            paddingBottom: "10px",
                            position: "sticky",
                            left: "0",
                            backgroundColor: "white",
                            zIndex: 10,
                        }}
                    >
                        Channel
                    </div>
                    {getEventTimeWindow().window.map((h) => {
                        return (
                            <div
                                key={h}
                                style={{
                                    width: "180px",
                                    backgroundColor: "white",
                                }}
                            >
                                {h.toString().padStart(2, "0")}:00
                            </div>
                        );
                    })}
                </div>

                {multiChannel.map((c) => {
                    return (
                        <div
                            key={c.channelid}
                            style={{
                                display: "flex",
                                gap: "10px",
                                width: "fit-content",
                                borderBottom: "1px solid black",
                            }}
                        >
                            <div
                                style={{
                                    borderRight: "1px solid black",
                                    width: "150px",
                                    marginRight: "10px",
                                    position: "sticky",
                                    left: "0",
                                    zIndex: 1,
                                    backgroundColor: "white",
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        removeMultiChannel(c.channelid)
                                    }
                                    size="small"
                                    shape="circle"
                                    danger
                                    type="text"
                                >
                                    <MinusOutlined />
                                </Button>{" "}
                                {c.channelname}
                            </div>
                            {getEventTimeWindow().window.map((h) => {
                                let events = c.schedule[day][0].event.filter(
                                    (e) => {
                                        return (
                                            moment
                                                .unix(e.startTime)
                                                .format("HH") === h
                                        );
                                    }
                                );

                                return (
                                    <div
                                        key={`${c.channelid}-${h}`}
                                        style={{
                                            width: "180px",
                                            fontSize: ".9em",
                                        }}
                                    >
                                        {events.map((evt) => {
                                            return (
                                                <div
                                                    key={evt.evtId}
                                                    style={{
                                                        borderBottom:
                                                            "1px solid lightgrey",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {moment
                                                            .unix(evt.startTime)
                                                            .format("HH:mm")}
                                                    </span>{" "}
                                                    - {evt.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </Fragment>
    ) : (
        <Empty description="Add channel to custom guide" />
    );
};

export default CustomGuide;
