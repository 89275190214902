import { Fragment, useContext } from "react";
import { GuideContext } from "../../contexts/guide.context";
import Schedule from "../schedule/schedule.component";
import { Tabs, Button, Popover } from "antd";
import moment from "moment";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getChannelSchedule } from "../../utils/guide.utils";
import {toast} from 'react-hot-toast'

const TabContent = ({channel}) => {

    const { setMultiChannel, multiChannel, channels } = useContext(GuideContext);

    const onAddToCustomClick = async () => {
        let days = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        toast.promise(
            Promise.all(
                days.map(async (day) => {
                    return await getChannelSchedule(channel.channelid, day);
                })
            ),
            {
                loading: "Loading...",
                success: (schedule) => {
                    let channelIndex = channels.findIndex(
                        (c) => c.channelid === channel.channelid
                    );
                    let updatedMultiChannel = [...multiChannel];
                    updatedMultiChannel[channelIndex] = {
                        ...channel,
                        schedule,
                    };

                    localStorage.setItem(
                        "customGuide",
                        JSON.stringify(
                            updatedMultiChannel
                                .map((c) => c.channelid)
                                .filter((c) => c)
                        )
                    );

                    setMultiChannel(updatedMultiChannel.filter((c) => c));
                    return "Added to Custom Guide";
                },
                error: "Error Loading",
            }
        );


        // let schedule = await Promise.all(days.map(async (day) => {
        //     return await getChannelSchedule(channel.channelid, day);
        // }));
        // let channelIndex = channels.findIndex(c => c.channelid === channel.channelid);
        // let updatedMultiChannel = [...multiChannel];
        // updatedMultiChannel[channelIndex] = {
        //     ...channel,
        //     schedule
        // }

        // localStorage.setItem('customGuide', JSON.stringify(updatedMultiChannel.map(c => c.channelid).filter(c => c)));

        // setMultiChannel(updatedMultiChannel.filter(c => c));
    }

    return (
        <Fragment>
            <Tabs defaultActiveKey="0"
                items={[
                    {
                        key: 0,
                        label: moment().format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={0} />
                    },
                    {
                        key: 1,
                        label: moment().add(1, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={1} />
                    },
                    {
                        key: 2,
                        label: moment().add(2, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={2} />
                    },
                    {
                        key: 3,
                        label: moment().add(3, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={3} />
                    },
                    {
                        key: 4,
                        label: moment().add(4, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={4} />
                    },
                    {
                        key: 5,
                        label: moment().add(5, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={5} />
                    },
                    {
                        key: 6,
                        label: moment().add(6, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={6} />
                    },
                    {
                        key: 7,
                        label: moment().add(7, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={7} />
                    },
                    {
                        key: 8,
                        label: moment().add(8, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={8} />
                    },
                    {
                        key: 9,
                        label: moment().add(9, 'days').format("DD/MM/YYYY"),
                        children: <Schedule channel={channel} offset={9} />
                    }
                ]}
                tabBarExtraContent={{
                    left: (
                        <Popover
                            content="Add channel to custom guide"
                            placement="bottom"
                        >
                            <Button onClick={onAddToCustomClick} type="primary" size="small" shape="circle" icon={<PlusCircleOutlined />} style={{marginRight: '20px'}} />
                        </Popover>
                    )
                }}
            />
        </Fragment>
    )
}

export default TabContent;