import { useContext } from "react";
import { GuideContext } from "../../contexts/guide.context";
import { Tabs } from "antd";
import TabContent from "../tab-content/tab-content.component";
import CustomGuide from "../custom-guide/custom-guide.component";

const TabMenu = () => {
    const {channels} = useContext(GuideContext);

    return (
        <div style={{
            height: '100vh'
        }}>
            <Tabs
                defaultActiveKey={channels[0]?.channelid}
                tabPosition="left"
                style={{
                    height: '100%'
                }}
                items={[
                    {
                        label: 'Custom Guide',
                        key: 'custom',
                        children: <CustomGuide />
                    },
                    ...channels.map((c) => {
                        return {
                            label: c.channelname,
                            key: c.channelid,
                            children: <TabContent channel={c} />
                        }
                    })
                ]}
                type="card"
            />
        </div>
    )
};

export default TabMenu;