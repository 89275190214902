import { createContext, useState, useEffect } from "react";

import {getChannels, getChannelSchedule} from '../utils/guide.utils';

export const GuideContext = createContext({
    channels: [], setChannels: () => {},
    status: "", setStatus: () => {},
    multiChannel: [], setMultiChannel: () => {},
    customLoading: false, setCustomLoading: () => {},
});

export const GuideProvider = ({children}) => {
    const [channels, setChannels] = useState([]);
    const [status, setStatus] = useState('Loading');
    const [multiChannel, setMultiChannel] = useState([]);
    const [customLoading, setCustomLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const channels = await getChannels();
                setChannels(channels);
                setStatus('Channels Loaded');

                let customGuide = localStorage.getItem('customGuide');

                if(!customGuide) {
                    localStorage.setItem('customGuide', JSON.stringify([]));
                }else{
                    setCustomLoading(true);
                    customGuide = JSON.parse(customGuide);
                    let multi = await Promise.all(customGuide.map(async (c) => {
                        let channel = channels.find((ch) => ch.channelid === c);
                        let days = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
                        let schedule = await Promise.all(days.map(async (day) => {
                            return await getChannelSchedule(channel.channelid, day);
                        }));
                        return {
                            ...channel,
                            schedule
                        }
                    }));
                    setMultiChannel(multi);
                    setCustomLoading(false);
                }

            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const value = {
        channels, setChannels,
        status, setStatus,
        multiChannel, setMultiChannel,
        customLoading, setCustomLoading
    }

    return (
        <GuideContext.Provider value={value}>
            {children}
        </GuideContext.Provider>
    )

}