import { Spin } from "antd";

const Loading = ({loadingText = 'Loading...'}) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            <Spin
                size="large"
            >
                <div className="content">{loadingText}</div>
            </Spin>
        </div>
    )
}

export default Loading;